// Emotion types
/// <reference types="@emotion/react/types/css-prop" />
// import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

// Subtitles
// import campaignSubs from "file-loader!../assets/subtitles/campaign-fi.vtt";

import React, { useRef, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

// Images
import datacenter from "../assets/images/datacenter.jpg";
import solar from "../assets/images/solar.jpg";
import aviation from "../assets/images/aviation.jpg";
import wind from "../assets/images/wind.jpg";
import steelCase from "../assets/images/fi-flight4-22/steel-case.jpg";
import person from "../assets/images/fi-flight5-23/anna-borg.png";

// Video
import heroMp4 from "../assets/videos/fi-flight4-22/hero.mp4";
import heroWebM from "../assets/videos/fi-flight4-22/hero.webm";
import heroStandingMp4 from "../assets/videos/fi-flight4-22/hero-standing.mp4";
import heroStandingWebM from "../assets/videos/fi-flight4-22/hero-standing.webm";
import infoMp4 from "../assets/videos/info-fi.mp4";
import infoGraphic from "../assets/videos/fi-flight5-23/infographic.mp4";
import forestMp4 from "../assets/videos/fi-flight5-23/forest.mp4";
import forestWebM from "../assets/videos/fi-flight5-23/forest.webm";

import {
    Button,
    Case,
    FooterFi,
    // Headline,
    Layout,
    MapImage,
    Metadata,
    NavigationFi,
    VideoHero,
    locationsFinnish,
    QuoteAndImage,
    InfoVideo,
    Achievements,
    FlyingHat,
} from "../components";
import { Waypoint } from "react-waypoint";
import { Quiz } from "../components/quiz-fi";

// Styling

const StickyBtn = styled.a`
    position: sticky;
    top: 80px;
    z-index: 10;
`;

/*const FullImg = styled.div`
    position: relative;
    @media screen and (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;
    }
    h2 {
        position: absolute;
        color: #fff;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 470px;
        @media screen and (max-width: 768px) {
            font-size: 25px;
            line-height: 24px;
            top: 40%;
            width: 100%;
        }
    }
    img {
        width: 100%;
        @media screen and (max-width: 768px) {
            height: 200px;
            object-fit: cover;
            object-position: center;
        }
    }
`;*/

// const VideoWrapper = styled.div`
//     max-height: 660px;
//     overflow: hidden;
//     width: 100%;
//     margin-top: 0;
//     @media screen and (max-width: 768px) {
//         max-height: none;
//         section > div > div {
//             max-height: 470px;
//         }
//     }
//     section {
//         //padding-bottom: 41.25%;
//         padding-bottom: 32.25%;
//         margin-top: 0;
//     }
//     video {
//         object-fit: cover !important;
//         pointer-events: none !important;
//         object-position: bottom !important;
//     }
// `;

const HeroTextBox = styled.div`
    text-align: center;
    max-width: 50%;
    margin-bottom: 0px;

    h1 {
        font-size: 3.35rem;
    }

    p {
        font-size: 22px;
    }

    @media screen and (max-width: 768px) {
        max-width: 85%;
        p {
            font-size: 18px;
        }
    }
`;

const WaypointWrapper = styled.div`
    margin: 0;
    span {
        margin: 0;
    }
`;

const HydrogenProofpoint = styled.div`
    margin-bottom: 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 990px) {
        flex-direction: column;
    }

    .hydrogenBody {
        text-align: left;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 990px) {
            max-width: 100%;
        }

        .textPart {
            padding: 70px;
            background-color: #edf1f6;

            @media screen and (max-width: 1200px) {
                padding: 40px;
            }

            @media screen and (max-width: 990px) {
                padding: 30px;
            }
        }

        .quizPart {
            padding: 70px;

            @media screen and (max-width: 1200px) {
                padding: 3px 40px;
            }

            @media screen and (max-width: 990px) {
                max-width: 100%;
                padding: 30px;
            }

            @media screen and (max-width: 480px) {
                padding: 15px;
            }
        }
    }

    .hydrogenText {
        width: 80%;
        line-height: 2.2rem;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }
    }

    .hydrogenImage {
        width: 50%;

        @media screen and (max-width: 990px) {
            width: 100%;
        }
    }
`;

const ButtonRow = styled.div`
    margin-bottom: 25px;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;

    a {
        // width: 120px;

        &:first-of-type {
            // margin-right: 10px;
        }
    }

    @media screen and (max-width: 990px) {
        display: flex;
        margin-right: 0;
        margin-bottom: 0px;
    }

    button {
        background-color: #2071b5;
        color: white;
        border: none;
        border-radius: 26px;
        font-size: 14px;
        cursor: pointer;
        width: 150px;
        height: 45px;
        padding: 0;

        :hover {
            color: white;
        }

        @media screen and (max-width: 990px) {
            width: 140px;
            min-width: 0;
            padding: 0px 15px 0px 15px;
        }

        @media screen and (max-width: 480px) {
            font-size: 12px;
            padding: 0px 15px 0px 15px;
            width: 120px;
        }
    }
    @media screen and (max-width: 480px) {
        gap: 20px;
    }
`;

// Markup
export const Finnish = () => {
    const datacenterRef = useRef<HTMLDivElement>(null);
    const steelRef = useRef<HTMLDivElement>(null);
    const aviationRef = useRef<HTMLDivElement>(null);
    const oceanRef = useRef<HTMLDivElement>(null);
    const solarRef = useRef<HTMLDivElement>(null);
    const hydrogenRef = useRef<HTMLDivElement>(null);

    const [zoom, setZoom] = useState(4.2);

    // Waypoints
    const [playInfo, setPlayInfo] = useState(false);
    const [playInfo2, setPlayInfo2] = useState(false);
    const [casePoint1, setcasePoint1] = useState(false);
    const [casePoint2, setcasePoint2] = useState(false);
    const [casePoint3, setcasePoint3] = useState(false);
    const [casePoint4, setcasePoint4] = useState(false);
    const [casePoint5, setcasePoint5] = useState(false);

    const offsetValue = "30%";

    const vwoScript = (url: string, selector = "head", async = true) => {
        useEffect(() => {
            const element = document.querySelector(selector);
            const script = document.createElement("script");
            script.type = "text/plain";
            script.dataset.cmpSrc = url;
            script.async = async;
            script.className = "cmplazyload";
            script.dataset.cmpVendor = "s31";
            element?.appendChild(script);
            return () => {
                element?.removeChild(script);
            };
        }, [url]);
    };

    vwoScript("./vwoFi.js");

    const gtmScript = (url: string, selector = "head", async = true) => {
        useEffect(() => {
            const element = document.querySelector(selector);
            const script = document.createElement("script");
            script.type = "text/plain";
            script.dataset.cmpSrc = url;
            script.async = async;
            script.className = "cmplazyload";
            script.dataset.cmpVendor = "s905";
            element?.appendChild(script);
            return () => {
                element?.removeChild(script);
            };
        }, [url]);
    };

    gtmScript("./gtmFi.js");

    useEffect(() => {
        window.innerWidth > 768 ? setZoom(4.2) : setZoom(3.75);
    }, [zoom]);

    // Order important for scrollTo, same as array of map-markers
    const articleRefs = [
        steelRef,
        aviationRef,
        solarRef,
        datacenterRef,
        oceanRef,
        oceanRef,
    ];

    const isDesktop = useMediaQuery({
        query: "(min-width: 600px)",
    });

    const autoblockScript = (url: string, selector = "body", async = true) => {
        useEffect(() => {
            const element = document.querySelector(selector);
            const script = document.createElement("script");
            script.src = url;
            script.async = async;
            script.dataset.cmpAb = "1";
            script.dataset.cmpCodesrc = "1";
            script.dataset.cmpHost = "c.delivery.consentmanager.net";
            script.dataset.cmpCdn = "cdn.consentmanager.net";
            element?.appendChild(script);
            return () => {
                element?.removeChild(script);
            };
        }, [url]);
    };

    autoblockScript(
        "https://cdn.consentmanager.net/delivery/autoblocking/7322ad843057.js"
    );

    return (
        <>
            <Metadata />
            <NavigationFi />
            <Layout>
                <HeroTextBox>
                    <h1>Miksi fossiilivapaa?</h1>
                    <p>
                        Kaikki yksityisasiakkaille ja pk-yrityksille Suomessa
                        myymämme sähkö on tuotettu ilman fossiilisia
                        polttoaineita. Haluamme vähentää CO2-päästöjä
                        entisestään ja mahdollistaa fossiilivapaan elämän.
                        Fossiilivapaan sähkösopimuksen kautta olet mukana
                        työssämme. Lue miten.
                    </p>
                </HeroTextBox>

                {isDesktop ? (
                    <VideoHero
                        primary
                        webM={heroWebM}
                        mp4={heroMp4}
                    // subtitles={campaignSubs}
                    // subLang=""
                    // subLabel=""
                    ></VideoHero>
                ) : (
                    <VideoHero
                        primary
                        webM={heroStandingWebM}
                        mp4={heroStandingMp4}
                    // subtitles={campaignSubs}
                    // subLang=""
                    // subLabel=""
                    ></VideoHero>
                )}

                <StickyBtn
                    // style={{ marginTop: 0 }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.vattenfall.fi/sahkosopimukset/"
                >
                    <Button intent="primary">Tee sähkösopimus</Button>
                </StickyBtn>

                <FlyingHat />

                <HydrogenProofpoint ref={hydrogenRef}>
                    <div className="hydrogenImage">
                        <StaticImage
                            src="../assets/images/fi-flight4-22/hydrogen-half.png"
                            alt="hydrogen"
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="hydrogenBody">
                        <div className="textPart">
                            <h4>Muutamme päästöt vesihöyryksi</h4>
                            <p className="hydrogenText">
                                Vety voi korvata fossiiliset polttoaineet eri
                                teollisuudenaloilla, ja fossiilivapaan
                                sähkösopimuksen avulla olet mukana tässä
                                kehityksessä. Vedyn päästöt ovat vain
                                vesihöyryä, joten vetyteollisuus voi vähentää
                                CO2-päästöjä merkittävästi. Vetyä saadaan
                                erottamalla vedestä happi ja vety
                                elektrolyysillä eli sähköenergian avulla. Kun
                                sähkö tuotetaan fossiilivapaasti, myös vety on
                                fossiilivapaata.
                            </p>
                        </div>
                        <div className="quizPart">
                            <Quiz />
                        </div>
                    </div>
                </HydrogenProofpoint>
                {/* 
                <Headline
                    primary="Katso, kuinka kehitämme fossiilivapaata tulevaisuutta"
                    secondary="Fossiilivapaalla sähkösopimuksella olet mukana kehityksessä."
                /> */}

                {/* <div ref={steelRef} style={{ marginBottom: 0 }}>
                    <StaticImage
                        src="../assets/images/steel-full.jpg"
                        alt="aviation"
                        style={{ width: "100%" }}
                    />
                </div>

                <div style={{ maxWidth: "660px", textAlign: "center" }}>
                    <h2>Fossiilivapaa teräs</h2>
                    <p>
                        Fossiilivapaan teräksen kehitys voi vähentää maailman
                        hiilidioksidipäästöjä 7 %.
                    </p>

                    <div style={{ marginTop: "35px" }}>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.vattenfall.fi/fokuksessa/innovaatiot/hybrit-tekee-teraksen-valmistuksesta-fossiilivapaata"
                        >
                            <Button intent="primary">Lue lisää</Button>
                        </a>
                    </div>
                </div> */}

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint1(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint1}
                    ref={oceanRef}
                    image={wind}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Muutamme tuulisen sään hyväksi sääksi</h4>
                    <p>
                        Tuulinen sää ei ole huono sää. Päinvastoin, sillä se
                        tuottaa fossiilivapaata sähköä. Korkean huipunkäyttöajan
                        ansiosta merituulivoiman tuotanto sopii erityisen hyvin
                        fossiilivapaan vedyn tuotantoon. Fossiilivapaan
                        sähkösopimuksen kautta olet mukana tuulivoiman
                        kehityksessä.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.fi/vastuullisuus/fossiilivapaa-kehitys/tuulivoimalla-hiilipaastojen-vahentamiseen/"
                    >
                        Lue lisää
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setPlayInfo(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <InfoVideo video={infoGraphic} startVideo={playInfo} />

                <MapImage articleRefs={articleRefs} data={locationsFinnish} />

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setPlayInfo2(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <InfoVideo startVideo={playInfo2} video={infoMp4} />

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint2(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint2}
                    ref={aviationRef}
                    image={aviation}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#EDF1F6"
                >
                    <h4>Fossiilivapaa lentopolttoaine</h4>
                    <p>
                        Vähäpäästöisen, fossiilivapaalla sähköllä ja
                        kierrätetyllä hiilidioksidilla tuotetun
                        lentopolttoaineen kehittäminen voi antaa meille
                        mahdollisuuden jatkaa lentämistä tulevaisuudessa.
                        Fossiilivapaan sähkösopimuksen kautta olet mukana tässä
                        kehityksessä.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.fi/vastuullisuus/fossiilivapaa-kehitys/fossiilivapaa-ilmailu/"
                    >
                        Lue lisää
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint3(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint3}
                    ref={solarRef}
                    image={solar}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Lisää aurinkovoimaa</h4>
                    <p>
                        Joka vuosi yhä useampi talo pystyy hyödyntämään auringon
                        energiaa ja tuottamaan itse sähköä. Fossiilivapaan
                        sähkösopimuksen kautta olet mukana kehittämässä kotien
                        energiaratkaisuja.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.fi/vastuullisuus/fossiilivapaa-kehitys/kodin-energiaratkaisut/"
                    >
                        Lue lisää
                    </a>
                </Case>

                {/* <Quote
                    backgroundColor="#fff0ea"
                    textWidth="660px"
                    text="Teemme yhteistyötä monien kiinteistönomistajien kanssa auttaaksemme luomaan paikan päälle latausinfrastruktuuria."
                    quoteName="Vattenfallin vastuullisuusjohtaja"
                    quoteText="Annika Ramsköld"
                ></Quote> */}

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint4(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>
                <Case
                    inView={casePoint4}
                    ref={datacenterRef}
                    image={datacenter}
                    imageOn="right"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Päästöt pois internetistä</h4>
                    <p>
                        Datakeskukset eli konesalit tai palvelukeskukset ovat
                        digitalisoituvan maailmamme todellisia
                        energia-ahmatteja. Monet suuret yritykset siirtävät
                        datakeskuksiaan Pohjoismaihin vähentääkseen
                        hiilidioksidipäästöjään.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.fi/fokuksessa/innovaatiot/pohjoismaista-ratkaisu-ilmastoalykkaisiin-datakeskuksiin/"
                    >
                        Lue lisää
                    </a>
                </Case>

                <WaypointWrapper>
                    <Waypoint
                        onEnter={() => setcasePoint5(true)}
                        bottomOffset={offsetValue}
                    />
                </WaypointWrapper>

                <Case
                    inView={casePoint5}
                    ref={steelRef}
                    image={steelCase}
                    imageOn="left"
                    offset={50}
                    backgroundColor="#F2F2F2"
                >
                    <h4>Fossiilivapaa teräs</h4>
                    <p>
                        Fossiilivapaan teräksen tuotanto vaatii paljon
                        fossiilivapaata sähköä, mutta sen kehitys voi vähentää
                        maailman hiilidioksidipäästöjä 7 %. Fossiilivapaan
                        sähkösopimuksen kautta olet mukana tässäkin
                        kehityksessä.
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.vattenfall.fi/vastuullisuus/fossiilivapaa-kehitys/fossiilivapaa-teras/"
                    >
                        Lue lisää
                    </a>
                </Case>

                <QuoteAndImage
                    backgroundColor="white"
                    quoteText="”On tärkeää, että käytämme osaamistamme ja markkina-asemaamme auttaaksemme asiakkaitamme ja toimittajiamme vähentämään hiilidioksidipäästöjä.”"
                    name="Anna Borg"
                    role="Toimitusjohtaja"
                    src={person}
                />

                <VideoHero webM={forestWebM} mp4={forestMp4} minHeight>
                    <h2 className="video-hero-h2">
                        Uusiutuvaa vai fossiilivapaata sähköä?
                    </h2>
                    <div className="video-hero-container">
                        <p>
                            Kaikki Suomessa{" "}
                            <a
                                style={{
                                    color: "white",
                                    textDecoration: "underline",
                                }}
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.vattenfall.fi/sahkosopimukset/"
                            >
                                kotitalouksille
                            </a>{" "}
                            ja{" "}
                            <a
                                style={{
                                    color: "white",
                                    textDecoration: "underline",
                                }}
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.vattenfall.fi/yritysasiakkaat/pienyritykset/"
                            >
                                pienyrityksille
                            </a>{" "}
                            myymämme sähkö on tuotettu fossiilivapaasti. Se
                            tarkoittaa, että sähkön tuotantovaiheessa ei synny
                            käytännössä lainkaan hiilidioksidipäästöjä. Osa
                            sähköntuotantoon käytetystä energiasta on myös
                            uusiutuvaa.
                            <br />
                            <br />
                            Valitse sähkösopimus:
                        </p>
                        <ButtonRow>
                            <a
                                style={{
                                    textDecoration: "underline",
                                    color: "white",
                                }}
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.vattenfall.fi/sahkosopimukset/"
                            >
                                <button
                                    type="button"
                                    className="vf-btn vf-btn--sm btn--primary"
                                >
                                    Kotitalouksille
                                </button>
                            </a>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.vattenfall.fi/yritysasiakkaat/pienyritykset/"
                            >
                                <button
                                    type="button"
                                    className="vf-btn vf-btn--sm btn--primary"
                                >
                                    Pienyrityksille
                                </button>
                            </a>
                        </ButtonRow>
                    </div>
                </VideoHero>
                <Achievements />
            </Layout>
            <FooterFi />
        </>
    );
};
